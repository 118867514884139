import { createSignal, Signal } from "solid-js"
import { LikeableObjId } from "../../common/likes"
import { FetchFlowState, FetchInFlight } from "./flows"
import { TweetEntry } from "../../common/tweets"
import { UnixTimestamp } from "../../common/types"
import { ClientAccountEntry } from "./search"
import { getApiKeyData } from "./client-auth"
import { fetchJson } from "../../common/json-api"
import { getFlags } from "../flags"
import { clientLogInfo } from "../utils/log"

const LOGSYS = 'Likes'

export interface LikeUnknown {kind: 'unknown'}
export interface LikeUnliked {kind: 'unliked'}
export interface LikeLiking {kind: 'liking'}
export interface LikeUnliking {kind: 'unliking'}
export interface LikeLiked {kind: 'liked', at: number}

export type LikeState = LikeLiked | LikeLiking | LikeUnliked | LikeUnliking | LikeUnknown

export interface LikesDict {
    [Key: LikeableObjId]: Signal<LikeState>
}

export type LikesMap = Map<LikeableObjId, Signal<LikeState>>

export interface LikesResponse {
    [Key: LikeableObjId]: UnixTimestamp
}

export interface PageLikes {
    accounts: LikesDict,
    tweets: LikesDict
}

export enum LikesPageChapter {
    Tweets = 'Tweets',
    Accounts = 'Accounts'
}

export enum LatestTweetCount {
    Min = 'Min',
    Mid = 'Mid',
    Max = 'Max'
}

export interface LikesPageData {
    chapter: LikesPageChapter
    latestTweetCount: LatestTweetCount
}

export function makeLikesPageData() {
    const chapterStr = localStorage.getItem('likesPageChapter') ?? LikesPageChapter.Tweets
    const chapter = (() => {
        if (chapterStr == LikesPageChapter.Accounts) {
            return LikesPageChapter.Accounts
        }
        else {
            return LikesPageChapter.Tweets
        }
    })()

    const latestTweetCount = localStorage.getItem('latestTweetCount')
    const latestTweetCountEnum = ((latestTweetCount) => {
        if (latestTweetCount === LatestTweetCount.Max) {
            return LatestTweetCount.Max
        }
        else if (latestTweetCount == LatestTweetCount.Mid) {
            return LatestTweetCount.Mid
        }
        else {
            return LatestTweetCount.Min
        }
    })(latestTweetCount)

    return {chapter: chapter, latestTweetCount: latestTweetCountEnum}
}

const [likesPageData, setLikesPageData] = createSignal<LikesPageData>(makeLikesPageData())

export function changeLikesPageChapter(chapter: LikesPageChapter) {
    setLikesPageData({...getLikesPageData(), chapter: chapter})
    localStorage.setItem('likesPageChapter', chapter)
}

export function changeLatestTweetCount(count: LatestTweetCount) {
    setLikesPageData({...getLikesPageData(), latestTweetCount: count})
    localStorage.setItem('latestTweetCount', count)
}

export const getLikesPageData = likesPageData

export type FetchLikesInFlight = FetchInFlight<null>

export interface TweetLikesFlowResult {
    kind: 'tweetsResult'
    tweets: TweetEntry[]
}

export interface AccountLikesFlowResult {
    kind: 'accountsResult'
    accounts: ClientAccountEntry[]    
}

export type LikesFlowState = FetchFlowState<TweetLikesFlowResult | AccountLikesFlowResult, null>

export const [getLikesFlowState, changeLikesFlowState] = createSignal<LikesFlowState>({kind: 'empty'})

interface GetLikesReq {
    kind: 'getLikes'
    ids: LikeableObjId[]
    resolve: ((v: unknown) => void) | null
}

interface ForgetLikesReq {
    kind: 'forgetLikes'
    ids: LikeableObjId[]
}

interface AllLikesReq {
    kind: 'allLikes'
    resolvers: ((v: LikesMap) => void)[]
}

type LikesRequest = GetLikesReq | ForgetLikesReq | AllLikesReq

interface LikesToDate {
    [Key: LikeableObjId]: UnixTimestamp | null
}

interface AllLikesNotRequestedYet { kind: 'notRequestedYet' }
interface AllLikesRequestInProgress { kind: 'requestInProgress', req: AllLikesReq }
interface AllLikesComplete { kind: 'complete' }
type AllLikesState = AllLikesNotRequestedYet | AllLikesRequestInProgress | AllLikesComplete

export class ClientLikeService {
    _objects: LikesMap
    _requestQueue: LikesRequest[]
    _activeRequest: LikesRequest | null
    _allAccountLikesState: AllLikesState
    _allTweetLikesState: AllLikesState
    _apiGetByIds: string
    _apiAll: string

    constructor(apiGetByIds: string, apiAll: string) {
        this._objects = new Map()
        this._requestQueue = []
        this._activeRequest = null
        this._allAccountLikesState = {kind: 'notRequestedYet'}
        this._allTweetLikesState = {kind: 'notRequestedYet'}
        this._apiGetByIds = apiGetByIds
        this._apiAll = apiAll
        const self = this
        setInterval(() => { self._update() }, 50)
    }

    async requestLikesByIds(ids: LikeableObjId[]) {
        const request: GetLikesReq = {kind: 'getLikes', ids: ids, resolve: null}
        const promise = new Promise((resolve, reject) => { request.resolve = resolve })
        this._requestQueue.push(request)
        return promise
    }

    async requestAllLikes(): Promise<LikesMap> {
        const self = this
        if (this._allAccountLikesState.kind === 'notRequestedYet') {
            const allLikesRequest: AllLikesReq = {kind: 'allLikes', resolvers: []}
            this._requestQueue.push(allLikesRequest)
            this._allAccountLikesState = {kind: 'requestInProgress', req: allLikesRequest}
            return new Promise((resolve, reject) => {
                if (self._allAccountLikesState.kind === 'requestInProgress')
                    self._allAccountLikesState.req.resolvers.push(resolve) 
            })
        }
        else if (this._allAccountLikesState.kind === 'requestInProgress') {
            return new Promise((resolve, reject) => {
                if (self._allAccountLikesState.kind === 'requestInProgress')
                    self._allAccountLikesState.req.resolvers.push(resolve) 
            })            
        }
        else {
            return this._getAllLiked()
        }
    }

    forgetLikes(ids: LikeableObjId[]) {
        this._requestQueue.push({kind: 'forgetLikes', ids: ids})
    }

    getLikeSignal(objId: LikeableObjId): Signal<LikeState> {
        if (!this._objects.has(objId)) {
            this._objects.set(objId, createSignal<LikeState>({kind: 'unknown'}))
        }
        return this._objects.get(objId) as Signal<LikeState>
    }

    _update() {
        const apiKey = getApiKeyData().validKey
        if (!apiKey) {
            return
        }
        if (this._requestQueue.length === 0 || this._activeRequest !== null) {
            return
        }

        this._activeRequest = this._requestQueue[0]
        this._requestQueue = this._requestQueue.slice(1)
        if (this._activeRequest.kind === 'getLikes') {
            this._getLikesByIds(apiKey, this._activeRequest)
        }
        else if (this._activeRequest.kind === 'forgetLikes') {
            this._forgetAccountLikes(this._activeRequest)
        }
        else if (this._activeRequest.kind === 'allLikes') {
            this._getAllLikes(apiKey, this._activeRequest)
        }
    }

    async _getLikesByIds(apiKey: string, request: GetLikesReq) {
        const result: LikesDict = {}
        const remoteIds = []
        for (const id of request.ids) {
            const signal = this._objects.get(id)
            if (signal && (signal[0]().kind === 'liked' || signal[0]().kind === 'unliked')) {
                result[id] = signal
            }
            else {
                remoteIds.push(id)
            }
        }

        if (remoteIds.length > 0) {
            const response = await fetchJson(new Request(
                `${getFlags().search.urlPrefix}/locked/likes/${this._apiGetByIds}`, {
                    method: 'POST',
                    body: JSON.stringify({ids: request.ids}),
                    headers: {'Authorization': `Bearer ${apiKey}`}
                }
            ))
            if (response instanceof Error) {
                this._requestQueue.unshift(request)
                this._activeRequest = null
                return
            }
            const likesData = response as LikesToDate
            for (const id in likesData) {
                if (!this._objects.has(id)) {
                    this._objects.set(id, createSignal<LikeState>({kind: 'unknown'}))
                }
                const signal = this._objects.get(id) as Signal<LikeState>
                signal[1](likesData[id] !== null ? {kind: 'liked', at: likesData[id]} : {kind: 'unliked'})
                result[id] = signal
            }
        }

        clientLogInfo(LOGSYS, 'GetLikesById done', request.ids, result)
        if (request.resolve) {
            request.resolve(result)
        }
        this._activeRequest = null
    }

    _forgetAccountLikes(req: ForgetLikesReq) {
        const toForget = []
        for (const id of req.ids) {
            const signal = this._objects.get(id)
            if (signal && signal[0]().kind !== 'liked') {
                toForget.push(id)
            }
        }
        for (const id of toForget) {
            this._objects.delete(id)
        }
        this._activeRequest = null
    }

    async _getAllLikes(apiKey: string, req: AllLikesReq) {
        const response = await fetchJson(
            new Request(
                `${getFlags().search.urlPrefix}/locked/likes/${this._apiAll}`,
                {method: 'GET', headers: {'Authorization': `Bearer ${apiKey}`}}
            )
        )
        if (response instanceof Error) {
            this._requestQueue.unshift(req)
            this._activeRequest = null
            return            
        }

        for (const id in (response as LikesResponse)) {
            if (!this._objects.has(id)) {
                this._objects.set(id, createSignal<LikeState>({kind: 'unknown'}))
            }
            const obj = this._objects.get(id) as Signal<LikeState>
            obj[1]({kind: 'liked', at: (response as LikesResponse)[id]})
        }

        this._allAccountLikesState = {kind: 'complete'}
        const allLiked = this._getAllLiked()
        for (const resolve of req.resolvers) {
            resolve(allLiked)
        }
        this._activeRequest = null
    }

    _getAllLiked(): LikesMap {
        const objects = new Map()
        for (const [id, signal] of this._objects) {
            if (signal[0]().kind === 'liked') {
                objects.set(id, signal)
            }
        }
        return objects
    }
}

const tweetLikeService = new ClientLikeService('of-tweets', 'all-tweets')
const accountLikeService = new ClientLikeService('of-accounts', 'all-accounts')

export function getTweetLikeService() { return tweetLikeService }
export function getAccountLikeService() { return accountLikeService }
