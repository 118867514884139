import { For } from "solid-js/web"
import { getAccountLikeService, getTweetLikeService } from "../data/client-likes"
import { Accessor } from "solid-js"
import { HeartDiv, ThumbDiv } from "./view-hearts-thumbs"
import { encloseWithParagraph, prettyDate, replaceHyperLinks } from "../utils/text"
import { TweetEntry } from "../../common/tweets"

export interface TweetViewProps {
    tweet: TweetEntry,
    index: Accessor<number>
}

export const TweetView = (props: TweetViewProps) => {
    return <div class="search-result-entry">
        <div class="account-name">
            <div class="person-link">
                <a href={props.tweet.accountUrl} target="_blank">
                    {props.index() + 1})&nbsp;
                    {
                        props.tweet.accountDisplayName !== null && props.tweet.accountDisplayName.length > 0 ?
                            props.tweet.accountDisplayName :
                            props.tweet.accountUrl.replace("https://", "")
                    }
                </a>
            </div>
            <HeartDiv
                like={getAccountLikeService().getLikeSignal(props.tweet.accountId)}
                account={props.tweet.accountId} 
            />
        </div>
        <div class="post-content" innerHTML={
            encloseWithParagraph(replaceHyperLinks(props.tweet.content))
        }>
        </div>
        <div class="post-time-row">
            <div class="post-time">
                <a href={props.tweet.tweetUrl} target="_blank">
                    {prettyDate(new Date(props.tweet.creationTime))}
                </a>
            </div>
            <ThumbDiv
                like={getTweetLikeService().getLikeSignal(props.tweet.tweetId)}
                tweet={props.tweet.tweetId}
            />
        </div>
    </div>
}

export const FoundTweetsList = (props: {data: TweetEntry[]}) => {
    return <div class="entry-list">
        <For each={props.data}>{(tweet, index) =>
            <TweetView tweet={tweet} index={index} />
        }</For>
    </div>
}
