import { Show } from "solid-js/web"
import { getApiKeyData } from "../data/client-auth"
import { LikeLiked, LikeState } from "../data/client-likes"
import { toggleLike } from "../logic/likes-flow"
import { LikeableObjType } from "../../common/likes"
import { heartFullIcon, heartIcon, heartQuestionIcon, thumbFullIcon, thumbIcon, thumbQuestionIcon } from "../utils/icons"
import { Signal } from "solid-js"

export const HeartDiv = (props: {like: Signal<LikeState>, account: string}) => {
    const like = props.like[0]
    return <div
            classList={{
                "heart": true,
                "animate-like-scale": like().kind !== 'liked' && like().kind !== 'unliked'
            }}
            onClick={async () => {
                await toggleLike({id: props.account, type: LikeableObjType.Account}, props.like)
            }}
        >
        <Show when={getApiKeyData().validKey}>
            <Show when={like().kind === 'liked'}>
                <img src={heartFullIcon.toString()} title={`Liked at ${new Date((like() as LikeLiked).at)}`} />
            </Show>
            <Show when={like().kind === 'unliked'}>
                <img src={heartIcon.toString()} title="Not liked yet" />
            </Show>
            <Show when={like().kind !== 'liked' && like().kind !== 'unliked'}>
                <img src={heartQuestionIcon.toString()} title="Loading..." />
            </Show>
        </Show>
    </div>
}

export const ThumbDiv = (props: {like: Signal<LikeState>, tweet: string}) => {
    const like = props.like[0]
    return <div
            classList={{
                "thumb": true,
                "animate-like-scale": like().kind !== 'liked' && like().kind !== 'unliked'
            }}
            onClick={async () => {
                await toggleLike({id: props.tweet, type: LikeableObjType.Tweet}, props.like)
            }}
        >
        <Show when={getApiKeyData().validKey}>
            <Show when={like().kind === 'liked'}>
                <img src={thumbFullIcon.toString()} title={`Liked at ${new Date((like() as LikeLiked).at)}`} />
            </Show>
            <Show when={like().kind === 'unliked'}>
                <img src={thumbIcon.toString()} title="Not liked yet" />
            </Show>
            <Show when={like().kind !== 'liked' && like().kind !== 'unliked'}>
                <img src={thumbQuestionIcon.toString()} title="Loading..." />
            </Show>
        </Show>
    </div>
}
