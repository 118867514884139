import { createSignal } from "solid-js";

export enum DotStyle {
    RightOnly = 1,
    Both = 2
}

export const AnimatedDotsView = (props: {count?: number, interval?: number, label: string, dotStyle?: DotStyle}) => {
    const [count, setCount] = createSignal(0);

    const dotCount = props.count || 5
    const interval = props.interval || 500
    const dotStyle = props.dotStyle || DotStyle.Both
    const label = props.label

    const dots = () => { return ".".repeat(count() % (dotCount + 1)) };
    setInterval(() => setCount(count() + 1), interval);

    if (dotStyle == DotStyle.Both) {
        return <div>
            {dots()}{label}{dots()}
        </div>
    }
    else {
        return <div>
            {label}{dots()}
        </div>
    }
}
