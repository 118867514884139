import { changeAugmentSearchQuery, changeMaxTime, changeMinTime, changeMinTweetLengthConstaint, changeQuery, changeSearchType, changeUseBluesky, changeUseMastodon, MinTweetLength, SearchType } from '../data/search'
import { searchFlow } from "../logic/search-flow"
import { Show } from "solid-js/web"
import { AnimatedDotsView } from "../utils/views"
import { getApiKeyData } from "../data/client-auth"
import { getSearchSettings } from "../data/search"
import { playScaleAnimation } from "../utils/animation"
import { removeQueryPrefix } from "../utils/text"
import { FoundTweetsList } from "./view-tweets"
import { FoundAccountsList } from "./view-accounts"
import { Enabled } from "../../common/types"
import { ClientAccountEntry, getSearchData, SearchRequestError } from '../data/search'
import { TweetEntry } from '../../common/tweets'
import { getFlags } from '../flags'

function MinTweetLengthOption<T extends MinTweetLength>(props: {elementId: string, countEnum: T}) {
    return <div
        id={props.elementId}
        classList={{highlight: getSearchSettings().minTweetLengthConstraint === props.countEnum}}
        onClick={(ev) => {
            playScaleAnimation(props.elementId)
            changeMinTweetLengthConstaint(props.countEnum) 
        }}
    >
        {props.countEnum}
    </div>
}

export const SearchQueryView = () => {
    return <>
        <div class="settings-box" id="search-settings-box">
            <div class="setting-container">
                <div class="complex-button">
                    <div
                        id="search-type-tweets"
                        classList={{highlight: getSearchSettings().searchType === SearchType.Tweets}}
                        onClick={(ev) => {
                            playScaleAnimation("search-type-tweets")
                            changeSearchType(SearchType.Tweets)
                        }}
                    >
                        Tweets
                    </div>
                    <div
                        id="search-type-people"
                        classList={{highlight: getSearchSettings().searchType === SearchType.Accounts}}
                        onClick={(ev) => {
                            playScaleAnimation("search-type-people")
                            changeSearchType(SearchType.Accounts)
                        }}
                    >
                        People
                    </div>
                </div>
            </div>
            <Show when={getSearchSettings().searchType === SearchType.Tweets}>
                <div class="setting-container">
                <div class="search-setting-label">Length</div>
                    <div class="complex-button">
                        <MinTweetLengthOption elementId="tweet-length-any" countEnum={MinTweetLength.Any} />
                        <MinTweetLengthOption elementId="tweet-length-mid" countEnum={MinTweetLength.Mid} />
                        <MinTweetLengthOption elementId="tweet-length-long" countEnum={MinTweetLength.Long} />
                        <MinTweetLengthOption elementId="tweet-length-huge" countEnum={MinTweetLength.Huge} />
                    </div>
                </div>
            </Show>
            <div class="setting-container">
                <div class="search-setting-label">Bluesky</div>
                <div class="complex-button">
                    <div
                        id="use-bluesky-yes"
                        classList={{highlight: getSearchSettings().useBluesky === Enabled.Yes}}
                        onClick={(ev) => {
                            playScaleAnimation("use-bluesky-yes")
                            changeUseBluesky(Enabled.Yes)
                        }}
                    >
                        On
                    </div>
                    <div
                        id="use-bluesky-no"
                        classList={{highlight: getSearchSettings().useBluesky === Enabled.No}}
                        onClick={(ev) => {
                            playScaleAnimation("use-bluesky-no")
                            changeUseBluesky(Enabled.No)
                        }}
                    >
                        Off
                    </div>
                </div>
            </div>
            <div class="setting-container">
                <div class="search-setting-label">Mastodon</div>
                <div class="complex-button">
                    <div
                        id="use-mastodon-yes"
                        classList={{highlight: getSearchSettings().useMastodon === Enabled.Yes}}
                        onClick={(ev) => {
                            playScaleAnimation("use-mastodon-yes")
                            changeUseMastodon(Enabled.Yes)
                        }}
                    >
                        On
                    </div>
                    <div
                        id="use-mastodon-no"
                        classList={{highlight: getSearchSettings().useMastodon === Enabled.No}}
                        onClick={(ev) => {
                            playScaleAnimation("use-mastodon-no")
                            changeUseMastodon(Enabled.No)
                        }}
                    >
                        Off
                    </div>
                </div>
            </div>
            <Show when={getSearchSettings().searchType === SearchType.Tweets}>
                <div class="setting-container">
                    <div class="search-setting-label">From</div>
                    <input
                        class="smaller-input"
                        placeholder="YYYY-MM-DD"
                        value={getSearchSettings().minTime}
                        onInput={(ev) => { changeMinTime(ev.target.value); }}
                    />
                    <div class="search-setting-label">To</div>
                    <input
                        class="smaller-input"
                        placeholder="YYYY-MM-DD"
                        value={getSearchSettings().maxTime}
                        onInput={(ev) => { changeMaxTime(ev.target.value); }}
                    />
                </div>
            </Show>
            <Show when={false}>
                <div class="setting-container">
                    <div class="search-setting-label">Add search instruction to embedding</div>
                    <div class="complex-button">
                        <div
                            id="augment-sq-yes"
                            classList={{highlight: getSearchSettings().augmentSq === Enabled.Yes}}
                            onClick={(ev) => {
                                playScaleAnimation("augment-sq-yes")
                                changeAugmentSearchQuery(Enabled.Yes) 
                            }}
                        >
                            Yes
                        </div>
                        <div
                            id="augment-sq-no"
                            classList={{highlight: getSearchSettings().augmentSq === Enabled.No}}
                            onClick={(ev) => {
                                playScaleAnimation("augment-sq-no")
                                changeAugmentSearchQuery(Enabled.No)
                            }}
                        >
                            No
                        </div>
                    </div>
                </div>
            </Show>
        </div>
        <div class="search-bar">
            <div class="complex-button">
                <div
                    id="explore-button"
                    class="highlight enlarged-padding"
                    onClick={async (ev) => {
                        playScaleAnimation("explore-button")
                        await searchFlow(getApiKeyData().validKey)
                    }}
                >
                    Explore
                </div>
            </div>
            <input
                value={getSearchSettings().query}
                onInput={(ev) => { changeQuery(ev.target.value) }}
                onKeyPress={async (ev) => {
                    /** @ts-ignore */ // .value exists on Input target.
                    if (ev.key == "Enter" && ev.target.value.length > 0) {
                        await searchFlow(getApiKeyData().validKey)
                    }
                }}
                placeholder="Enter a sentence..."
            />
        </div>
    </>
}

export const SearchResultView = () => {
    return <div class="page-main-content">
        <Show when={getSearchData().page > 0}>
            <Show when={getSearchData().data.kind === 'accounts'}>
                <FoundAccountsList data={getSearchData().data.entries as ClientAccountEntry[]} />
            </Show>
            <Show when={getSearchData().data.kind === 'tweets'}>
                <FoundTweetsList data={getSearchData().data.entries as TweetEntry[]} />
            </Show>
        </Show>
        <Show when={getSearchData().request.kind === 'inFlight'}>
            <div class="page-content-being-fetched">
                <AnimatedDotsView
                    label={
                        "Searching " + 
                        (
                            getSearchData().settings.searchType === SearchType.Tweets ? "tweets" : "people"
                        ) + 
                        " associated with «" + removeQueryPrefix(getSearchData().settings.query) + "»"
                    }
                    count={3}
                />
            </div>
            <div class="cup-of-tea">It may take a while... Perhaps, a cup of tea?</div>
        </Show>
        <Show when={
            getSearchData().request.kind !== 'inFlight' &&
            getSearchData().page < getFlags().search.maxPageCount &&
            getSearchData().page !== 0
        }>
            <div class="more-button">
                <div class="complex-button">
                    <div
                        id="more-button"
                        class="highlight enlarged-padding"
                        onClick={async (ev) => {
                            playScaleAnimation("more-button")
                            await searchFlow(getApiKeyData().validKey)
                        }}
                    >
                        More...
                    </div>
                </div>
            </div>
        </Show>
        <Show when={getSearchData().request.kind === 'error'}>
            <div class="error">
                <b>Search failed!</b>
                <div innerHTML={(getSearchData().request as SearchRequestError).error.message}></div>
            </div>
        </Show>
    </div>
}
