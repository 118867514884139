export function toSet<T>(xs: Iterable<T>): Set<T> {
    const result = new Set<T>()
    for (const item of xs) {
        result.add(item)
    }
    return result
}

export function toArray<T>(xs: Iterable<T>): T[] {
    return [...xs]
}

export function removeDuplicatesFromArray<T>(arr: T[]): T[] {
    const values = new Set<T>()
    const result = []
    for (const item of arr) {
        if (!values.has(item)) {
            values.add(item)
            result.push(item)
        }
    }
    return result
}

export function shuffleArrayInPlace<T>(arr: T[]) {
    for (let i = arr.length - 1; i >= 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [arr[i], arr[j]] = [arr[j], arr[i]];
    }
}

export function* zip<A, B>(a: Iterable<A>, b: Iterable<B>): Generator<[A, B]> {
    const iters = [a, b].map((iterable) => iterable[Symbol.iterator]())
    let next = iters.map((iter) => iter.next().value)
    while (anyOf(next)) {
        yield next as [A, B]
        next = iters.map((iter) => iter.next().value)
    }
  
    function anyOf<T>(arr: T[]): boolean {
        return arr.some(v => v !== undefined)
    }
}

export function areSetsEqual<T>(a: Set<T>, b: Set<T>): boolean {
    for (const elem of a) {
        if (!b.has(elem)) {
            return false
        }
    }
    for (const elem of b) {
        if (!a.has(elem)) {
            return false
        }
    }
    return true
}
