/* JSON */

export type NonNullJsonPrimitive = string | number | boolean
export type JsonPrimitive = NonNullJsonPrimitive | null;
export type JsonArray = Json[];
export type JsonObject = { [key: string]: Json };
export type JsonComposite = JsonArray | JsonObject;
export type Json = JsonPrimitive | JsonComposite;
export type NonNullJson = NonNullJsonPrimitive | JsonComposite

/* Common operations' results */

export enum ResultAdd {
    Yes = 'Yes',
    ExistsAlready = 'ExistsAlready'
}

export enum ResultExists {
    Yes = 'Yes',
    No = 'No'
}

export enum ResultDel {
    Yes = 'Yes',
    DoesNotExist = 'DoesNotExist'
}

export enum ResultHas {
    Yes = 'Yes',
    No = 'No'
}

export function resultToBool(result: ResultAdd | ResultExists | ResultDel | ResultHas): boolean {
    if (
        result === ResultAdd.Yes || result === ResultExists.Yes || result === ResultDel.Yes ||
        result === ResultHas.Yes
    ) {
        return true
    }
    else {
        return false
    }
}

/* Seewallee specific types */

export type AccountId = string
export type TweetId = string

export enum Realm {
    Mastodon = 'Mastodon',
    Bluesky = 'Bluesky'
}

/* General types */

export type UnixTimestamp = number

export enum Enabled {
    Yes = 'Yes',
    No = 'No'
}
