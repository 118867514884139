import { ErrorCode, errorCodeDescriptions } from "./error-codes"
import { humanReadableTimeDelta } from "./time"
import { Json, NonNullJson } from "./types"

async function awaitTextAndParseJson(response: Response): Promise<NonNullJson | Error> {
    let responseText
    try {
        responseText = await response.text()
    } catch (e) {
        return e as Error
    }

    try {
        return JSON.parse(responseText)
    } catch (e) {
        return new Error(`Malformed JSON response from the server: ${e}`)
    }
}

export async function fetchJson(request: Request, signal?: AbortSignal): Promise<Json | Error> {
    signal = signal ? AbortSignal.any([signal, AbortSignal.timeout(60000)]) : AbortSignal.timeout(60000)
    let response
    try {
        response = await fetch(request, {signal: signal})
    } catch (e) {
        return e as Error
    }

    if (!response.ok) {
        if (response.status === 401) {
            return new Error('Your login credentials are invalid.')
        }

        const responseJson = await awaitTextAndParseJson(response)
        if (responseJson instanceof Error) {
            return new Error(`unexpected error -- ${responseJson} [${response.status}]`)
        }
        if ((typeof responseJson !== 'object')) {
            return new Error('unexpected error -- response is not a JSON object [${response.status}]')
        }
        if (!('error' in responseJson)) {
            return new Error('unexpected error -- response has no "error" field [${response.status}]')
        }
        if (response.status === 429) {
            const retryAt = responseJson['retryAt'] as number
            const delta = retryAt - Date.now()
            let readableDelta = delta > 0 ? humanReadableTimeDelta(delta) : '0s'
            return new Error(`Too many requests, please retry in ${readableDelta}`)
        }
        return new Error(
            `${responseJson['error']} -- ${errorCodeDescriptions[responseJson['error'] as ErrorCode]} [${response.status}]`
        )
    }

    let responseJson
    try
    {
        responseJson = await awaitTextAndParseJson(response)
        if (responseJson instanceof Error) {
            return new Error(`Failed to read response: ${responseJson}`)
        }
        if ((typeof responseJson !== 'object')) {
            return new Error('Malformed JSON response from the server: is not an object')
        }
        if (!('result' in responseJson)) {
            return new Error('Malformed JSON response from the server: result is not present')
        }
    } catch (e) {
        return e as Error
    }
    return responseJson['result']
}
