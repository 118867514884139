import { fetchCatchingErrors } from "../utils/http"
import { getApiKeyData } from "../data/client-auth"
import { changeLikesFlowState, getAccountLikeService, getLikesPageData, getTweetLikeService, LikeLiked, LikesPageChapter, LikeState } from "../data/client-likes"
import { getFlags } from "../flags"
import { LikeableObj, LikeableObjType } from "../../common/likes"
import { createSignal, Signal } from "solid-js"
import { changeLastSelectedMenuItem, MenuItem } from "../data/menu"
import { AccountId } from "../../common/types"
import { toArray } from "../../common/data-structures"
import { getClientAccountService, getClientTweetService, getLatestTweetService } from "../data/client-tweets"
import { TweetsState } from "../data/search"

export async function toggleLike(obj: LikeableObj, signal: Signal<LikeState>) {
    if (!getApiKeyData().validKey) {
        return
    }
    const currentState = signal[0]()
    if (currentState.kind !== 'liked' && currentState.kind !== 'unliked') {
        return
    }

    signal[1](currentState.kind === 'liked' ? {kind: 'unliking'} : {kind: 'liking'})
    const endpointAction = currentState.kind === 'liked' ? 'unlike' : 'like'
    const endpointObj = obj.type === LikeableObjType.Account ? 'account' : 'tweet'

    const result = await fetchCatchingErrors(new Request(
        `${getFlags().search.urlPrefix}/locked/likes/${endpointAction}-${endpointObj}?id=${obj.id}`, {
            method: 'GET', headers: {'Authorization': `Bearer ${getApiKeyData().validKey}`}
        }
    ))
    if (result instanceof Error) {
        return
    }
    const jsonResult = JSON.parse(result)
    if ('error' in jsonResult) {
        return
    }
    signal[1](currentState.kind === 'liked' ? {kind: 'unliked'} : {kind: 'liked', at: new Date().getTime()})
}

export async function openLikesPage() {
    const apiKey = getApiKeyData().validKey
    if (apiKey) {
        changeLastSelectedMenuItem(MenuItem.Likes)
        if (getLikesPageData().chapter === LikesPageChapter.Tweets) {
            await tweetLikesPageFlow(apiKey)
        }
        else {
            await accountLikesPageFlow(apiKey)
        }
    }
}

let requestCounter = 0

export async function tweetLikesPageFlow(apiKey: string) {
    const thisRequestNum = ++requestCounter
    changeLikesFlowState({kind: 'inFlight', abort: new AbortController(), details: null})

    const tweetLikes = await getTweetLikeService().requestAllLikes()
    const tweets = await getClientTweetService().getAccountsByIds(Array.from(tweetLikes.keys()))
    if (thisRequestNum !== requestCounter) {
        return
    }
    if (tweets instanceof Error) {
        changeLikesFlowState({kind: 'error', error: tweets})
        return
    }

    const tweetsAndLikedAt = []
    const accountsOfTweets = new Set<AccountId>
    for (const [tweetId, tweet] of tweets.entries()) {
        const timestamp = ((tweetLikes.get(tweetId) as Signal<LikeState>)[0]() as LikeLiked).at
        tweetsAndLikedAt.push({tweet: tweet, timestamp: timestamp})
        accountsOfTweets.add(tweet.accountId)
    }
    tweetsAndLikedAt.sort((a, b) => { return b.timestamp - a.timestamp })
    getAccountLikeService().requestLikesByIds(toArray(accountsOfTweets))

    changeLikesFlowState({
        kind: 'tweetsResult',
        tweets: tweetsAndLikedAt.map((v) => { return v.tweet })
    })
}

export async function accountLikesPageFlow(apiKey: string) {
    const thisRequestNum = ++requestCounter
    changeLikesFlowState({kind: 'inFlight', abort: new AbortController(), details: null})

    const accountLikes = await getAccountLikeService().requestAllLikes()
    const accounts = await getClientAccountService().getAccountsByIds(Array.from(accountLikes.keys()))
    if (thisRequestNum !== requestCounter) {
        return
    }
    if (accounts instanceof Error) {
        changeLikesFlowState({kind: 'error', error: accounts})
        return
    }

    const accountsAndLikedAt = []
    for (const [accountId, account] of accounts.entries()) {
        const timestamp = ((accountLikes.get(accountId) as Signal<LikeState>)[0]() as LikeLiked).at
        accountsAndLikedAt.push({
            account: {...account, tweets: createSignal<TweetsState>({kind: 'fetchingTweets'})},
            timestamp: timestamp
        })
    }
    accountsAndLikedAt.sort((a, b) => { return b.timestamp - a.timestamp })

    // Get latest tweets of accounts.
    const finalAccounts = accountsAndLikedAt.map((v) => { return v.account })
    changeLikesFlowState({
        kind: 'accountsResult',
        accounts: finalAccounts
    })
    for (const account of finalAccounts) {
        getLatestTweetService().getLatestTweetsOfAccount(
            account.accountId, getLikesPageData().latestTweetCount
        ).then((result) => {
            if (result instanceof Error) {
                account.tweets[1]({kind: 'error', error: result})
                return
            }
            getTweetLikeService().requestLikesByIds(result.map((v) => { return v.tweetId }))
            account.tweets[1]({kind: 'tweetsFetched', data: result})
        })
    }
}
