import { createSignal } from 'solid-js'

export class KeyVerificationInFlight {}

export type KeyVerificationFlowState = KeyVerificationInFlight | Error | null;

export const [getKeyVerificationFlowState, changeKeyVerificationFlowState] = 
    createSignal<KeyVerificationFlowState>(null)

export interface KeyData {
    currentKey: string
    validKey: string | null
}

export function isLoggedIn(data: KeyData) {
    return data.validKey && data.validKey.length > 0
}

function makeKeyData() {
    return {
        currentKey: localStorage.getItem('currentKey') ?? '',
        validKey: localStorage.getItem('validKey')
    }
}

export function changeCurrentKey(key: string) {
    setApiKeyData({...getApiKeyData(), currentKey: key});
    localStorage.setItem('currentKey', key);
}

export function apiKeyValidated(apiKey: string) {
    setApiKeyData({...getApiKeyData(), validKey: apiKey})
    localStorage.setItem('validKey', apiKey)
}

export function dropValidKey() {
    setApiKeyData({...getApiKeyData(), validKey: null})
    localStorage.removeItem('validKey')
}

const [apiKeyData, setApiKeyData] = createSignal<KeyData>(makeKeyData());

export const getApiKeyData = apiKeyData;
