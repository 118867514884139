import { createSignal, Signal } from 'solid-js'
import { AccountData, TweetData, TweetEntry } from '../../common/tweets'
import { Enabled } from '../../common/types'

/***************************/
/****** Latest tweets ******/
/***************************/

export interface FetchingTweets {
    kind: 'fetchingTweets'
}

export interface FetchingTweetsError {
    kind: 'error'
    error: Error
}

export interface TweetsFetched {
    kind: 'tweetsFetched',
    data: TweetData[]
}

export type TweetsState = FetchingTweets | FetchingTweetsError | TweetsFetched

export type ClientAccountEntry = AccountData & {tweets: Signal<TweetsState>}


/*****************************/
/****** Search settings ******/
/*****************************/

export enum SearchType {
    Accounts = 'Accounts',
    Tweets = 'Tweets'
}

export enum MinTweetLength {
    Any = 'Any',
    Mid = 'Mid',
    Long = 'Long',
    Huge = 'Huge'
}

export interface SearchSettings {
    searchType: SearchType
    query: string
    augmentSq: Enabled
    useMastodon: Enabled
    useBluesky: Enabled
    minTweetLengthConstraint: MinTweetLength
    minTime: string
    maxTime: string
}

export function makeSearchSettings() {
    const useMastodon = localStorage.getItem('useMastodon') ?? Enabled.Yes
    const useMastodonEnum = useMastodon === Enabled.Yes ? Enabled.Yes : Enabled.No

    const useBluesky = localStorage.getItem('useBluesky') ?? Enabled.Yes
    const useBlueskyEnum = useBluesky === Enabled.Yes ? Enabled.Yes : Enabled.No
    
    const lastSearchStr = localStorage.getItem('lastSearchType') ?? ''
    const lastSearch = (() => {
        if (lastSearchStr === SearchType.Accounts) {
            return SearchType.Accounts
        }
        else {
            return SearchType.Tweets
        }
    })()

    const minTweetLengthConstraint = localStorage.getItem('minTweetLengthConstaint') ?? MinTweetLength.Any
    const minTweetLengthConstrainEnum = (() => {
        if (minTweetLengthConstraint === MinTweetLength.Long) {
            return MinTweetLength.Long
        }
        else if (minTweetLengthConstraint === MinTweetLength.Huge) {
            return MinTweetLength.Huge
        }
        else if (minTweetLengthConstraint === MinTweetLength.Mid) {
            return MinTweetLength.Mid
        }
        else {
            return MinTweetLength.Any
        }
    })()

    const augmentSq = localStorage.getItem('augmentSearchQuery') ?? Enabled.No
    const augmentSqEnum = augmentSq === Enabled.Yes ? Enabled.Yes : Enabled.No

    return {
        query: '',
        searchType: lastSearch,
        augmentSq: augmentSqEnum,
        useMastodon: useMastodonEnum,
        useBluesky: useBlueskyEnum,
        minTweetLengthConstraint: minTweetLengthConstrainEnum,
        minTime: localStorage.getItem('minTime') ?? '',
        maxTime: localStorage.getItem('maxTime') ?? ''
    }
}

export function changeSearchType(type: SearchType) {
    localStorage.setItem('lastSearchType', type)
    setSearchSettings({...getSearchSettings(), searchType: type})
}

export function changeAugmentSearchQuery(augmentSq: Enabled) {
    localStorage.setItem('augmentSearchQuery', augmentSq)
    setSearchSettings({...getSearchSettings(), augmentSq: augmentSq})    
}

export function changeUseMastodon(use: Enabled) {
    localStorage.setItem('useMastodon', use)
    setSearchSettings({...getSearchSettings(), useMastodon: use})
}

export function changeUseBluesky(use: Enabled) {
    localStorage.setItem('useBluesky', use)
    setSearchSettings({...getSearchSettings(), useBluesky: use})
}

export function changeMinTweetLengthConstaint(type: MinTweetLength) {
    localStorage.setItem('minTweetLengthConstaint', type)
    setSearchSettings({...getSearchSettings(), minTweetLengthConstraint: type})
}

export function changeMinTime(time: string) {
    localStorage.setItem('minTime', time)
    setSearchSettings({...getSearchSettings(), minTime: time})
}

export function changeMaxTime(time: string) {
    localStorage.setItem('maxTime', time)
    setSearchSettings({...getSearchSettings(), maxTime: time})
}

export function changeQuery(query: string) {
    setSearchSettings({...getSearchSettings(), query: query})
}

const [searchSettings, setSearchSettings] = createSignal<SearchSettings>(makeSearchSettings());

export const getSearchSettings = searchSettings;


/*************************/
/****** Search flow ******/
/*************************/

export interface SearchRequestIdle {
    kind: 'idle'
}

export interface SearchRequestInFlight {
    kind: 'inFlight'
    counter: number
    abort: AbortController
}

export interface SearchRequestError {
    kind: 'error'
    error: Error
}

export interface AccountSearchData {
    kind: 'accounts'
    entries: ClientAccountEntry[],
}

export interface TweetSearchData {
    kind: 'tweets'
    entries: TweetEntry[],
}

export interface SearchData {
    data: AccountSearchData | TweetSearchData
    settings: SearchSettings
    request: SearchRequestIdle | SearchRequestInFlight | SearchRequestError
    page: number
}

export function makeEmptyAccountSearchData(): SearchData {
    return { 
        data: { kind: 'accounts', entries: [] },
        settings: getSearchSettings(),
        page: 0,
        request: { kind: 'idle' } 
    }
}

export function makeEmptyTweetSearchData(): SearchData  {
    return { 
        data: { kind: 'tweets', entries: [] },
        settings: getSearchSettings(),
        page: 0,
        request: { kind: 'idle' }
    }
}

function makeSearchData(): SearchData {
    if (getSearchSettings().searchType === SearchType.Accounts) {
        return makeEmptyAccountSearchData()
    }
    else {
        return makeEmptyTweetSearchData()
    }
}

export const [getSearchData, setSearchData] = createSignal<SearchData>(makeSearchData())
