import { render } from "solid-js/web"
import { MenuView } from "./views/view-menu"
import { VerifyKeyView } from "./views/view-auth"
import { SearchQueryView, SearchResultView } from "./views/view-search"
import { Show } from "solid-js/web"
import { MenuItem, getSelectedMenuItem } from "./data/menu"
import { LikesView } from "./views/view-likes"
import { iconsForLikes } from "./utils/icons"
import { ErrorBoundary, onMount } from "solid-js"
import { openLikesPage } from "./logic/likes-flow"

const App = () => {
    // Preload icons for likes.
    for (const icon of iconsForLikes) {
        const image = new Image()
        image.src = icon.toString()
    }

    onMount(async () => {
        if (getSelectedMenuItem().item === MenuItem.Likes) {
            openLikesPage()
        }
    })

    return <>
        <div class="header">
            <div class="title">Seewallee Search (&alpha;)</div>
            <div class="subtitle">Explore the world of people's ingenuity.</div>
            <div class="subtitle">See connections. Encounter surprises.</div>
            <div class="subtitle"> Find inspiration. Find new friends.</div>
        </div>
        <div class="main">
            <MenuView />
            <ErrorBoundary fallback={
                (err) =>
                <div class="ui-error">
                    <div><b>Unexpected UI error: {err.message}</b></div>
                    <div>{err.stack}</div>
                </div>
            }>
                <Show when={getSelectedMenuItem().item === MenuItem.Search
                }>
                    <SearchQueryView/>
                    <SearchResultView/>
                </Show>
                <Show when={getSelectedMenuItem().item === MenuItem.Login}>
                    <VerifyKeyView/>
                </Show>
                <Show when={getSelectedMenuItem().item === MenuItem.Likes}>
                    <LikesView/>
                </Show>
            </ErrorBoundary>
        </div>
        <div class="footer">
            <div class="title">Seewallee</div>
            <div class="subtitle">Vision for everyone</div>
            <div class="icon-attribution">
                <a href="https://www.flaticon.com/free-icons/gear" title="gear icons">Gear icons created by Freepik - Flaticon</a>
                <br/>
                <a href="https://www.flaticon.com/free-icons/heart" title="heart icons">Heart icons created by Chanut - Flaticon</a>
                <br/>
                <a href="https://www.flaticon.com/free-icons/thumb" title="thumb icons">Thumb icons created by Anggara - Flaticon</a>
            </div>
        </div>
    </>
};

render(() => <App/>, document.body);
