import { getApiKeyData } from "../data/client-auth";
import { Show } from "solid-js/web";
import { changeLastSelectedMenuItem, getSelectedMenuItem, MenuItem } from "../data/menu";
import { logOut } from "../logic/auth-flow";
import { openLikesPage } from "../logic/likes-flow";

export const MenuView = () => {
    return <div class="menu">
        <div class="left-part">
            <div classList={{
                    "menu-item": true,
                    "selected": getSelectedMenuItem().item === MenuItem.Search
                }}
                onClick={async (ev) => { 
                    changeLastSelectedMenuItem(MenuItem.Search)
                }}
            >
                Home
            </div>
            <Show when={getApiKeyData().validKey}>
                <div classList={{
                        "menu-item": true,
                        "selected": getSelectedMenuItem().item === MenuItem.Likes
                    }}
                    onClick={async (ev) => { 
                        openLikesPage()
                    }}
                >
                    Likes
                </div>
            </Show>
        </div>
        <div class="right-part">
            <div class="menu-item">
                <a href="https://x.com/SeewalleeSearch" target="_blank">X</a>
            </div>
            <div class="menu-item">
                <a href="https://reddit.com/r/seewalleesearch" target="_blank">Reddit</a>
            </div>
            <Show when={!getApiKeyData().validKey}>
                <div classList={
                    {
                        "menu-item": true,
                        "selected": getSelectedMenuItem().item === MenuItem.Login}
                    }
                    onClick={(ev) => { changeLastSelectedMenuItem(MenuItem.Login) }}
                >
                    Log in
                </div>
            </Show>
            <Show when={getApiKeyData().validKey}>
                <div classList={
                    {
                        "menu-item": true,
                        "selected": getSelectedMenuItem().item === MenuItem.Login}
                    }
                    onClick={(ev) => { logOut() }}
                >
                    Log out
                </div>
            </Show>
        </div>
    </div>
}
