export async function fetchCatchingErrors(
    request: Request
): Promise<string | Error> {
    try {
        const response = await fetch(request, {signal: AbortSignal.timeout(60000)})
        if (!response.ok) {
            return new Error(`Bad response, code ${response.status}`)
        }
        else {
            return await response.text()
        }
    } catch (e) {
        if ((e as Error).name === 'TimeoutError') {
            return new Error('request timeout');
        }
        else {
            return e as Error
        }
    }
}
