export enum ErrorCode {
    ObjectLikedAlready = 'L001',
    ObjectWasNotLikedBefore = 'L002',
    LikeLimitReached = 'L003',

    InvalidCardinalityOfSearchResult = 'S001',
    InvalidSearchRealm = 'S002',

    TooManyEntitiesRequested = 'G001',
    InvalidEntityIdsPassed = 'G002',
    InvalidCountRequested = 'G003',
    OtherError = 'G004',
    TooManyRequests = 'G005',
    ServiceOverloaded = 'G006'
}

export const errorCodeDescriptions = {
    L001: 'Object already liked',
    L002: 'Object was not liked',
    L003: 'Maximum amount of likes reached',

    S001: 'Invalid count of search items requested',
    S002: 'Unknown tweeting platform specified',

    G001: 'Too many entities requested',
    G002: 'Parameter must be an array of string, but it is not',
    G003: 'Invalid item count requested',
    G004: 'Other error',
    G005: 'Too many requests',
    G006: 'Service is overloaded. Try again later'
}
