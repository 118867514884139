import { For, Show } from "solid-js"
import { AccountLikesFlowResult, changeLatestTweetCount, changeLikesPageChapter, getLikesFlowState, getLikesPageData, LatestTweetCount, LikesPageChapter, TweetLikesFlowResult } from "../data/client-likes"
import { playScaleAnimation } from "../utils/animation"
import { TweetView } from "./view-tweets"
import { AnimatedDotsView } from "../utils/views"
import { FetchError } from "../data/flows"
import { AccountEntryView } from "./view-accounts"
import { openLikesPage } from "../logic/likes-flow"

function LatestTweetCountOption<T extends LatestTweetCount>(props: {elementId: string, countEnum: T}) {
    return <div
        id={props.elementId}
        classList={{highlight: getLikesPageData().latestTweetCount === props.countEnum}}
        onClick={(ev) => {
            console.log('CLicking?')
            playScaleAnimation(props.elementId)
            const reopen = getLikesPageData().latestTweetCount !== props.countEnum
            changeLatestTweetCount(props.countEnum)
            if (reopen) {
                openLikesPage()
            }
        }}
    >
        {props.countEnum}
    </div>
}

export const LikesView = () => {
    return <>
        <div class="settings-box">
            <div class="complex-button">
                <div
                    id="likes-page-chapter-tweets"
                    classList={{
                        highlight: getLikesPageData().chapter === LikesPageChapter.Tweets
                    }}
                    onClick={(ev) => {
                        playScaleAnimation("likes-page-chapter-tweets")
                        if (getLikesPageData().chapter !== LikesPageChapter.Tweets) {
                            changeLikesPageChapter(LikesPageChapter.Tweets)
                            openLikesPage()
                        }
                    }}
                >
                    Tweets
                </div>
                <div
                    id="likes-page-chapter-accounts"
                    classList={{
                        highlight: getLikesPageData().chapter === LikesPageChapter.Accounts
                    }}
                    onClick={(ev) => {
                        playScaleAnimation("likes-page-chapter-accounts")
                        if (getLikesPageData().chapter !== LikesPageChapter.Accounts) {      
                            changeLikesPageChapter(LikesPageChapter.Accounts)
                            openLikesPage()
                        }
                    }}
                >
                    People
                </div>
            </div>
            <Show when={getLikesPageData().chapter === LikesPageChapter.Accounts}>
                <div class="setting-container">
                    <div class="search-setting-label">Tweet count</div>
                    <div class="complex-button">
                        <LatestTweetCountOption elementId="latest-tweet-count-1" countEnum={LatestTweetCount.Min} />
                        <LatestTweetCountOption elementId="latest-tweet-count-2" countEnum={LatestTweetCount.Mid} />
                        <LatestTweetCountOption elementId="latest-tweet-count-3" countEnum={LatestTweetCount.Max} />
                    </div>
                </div>
            </Show>
        </div>
        <div class="page-main-content">
            <Show when={getLikesFlowState().kind === 'inFlight'}>
                <div class="page-content-being-fetched">
                    <AnimatedDotsView label="Fetching your likes" count={3}></AnimatedDotsView>
                </div>
            </Show>
            <Show when={getLikesFlowState().kind === 'tweetsResult'}>
                <div class="entry-list">
                    <Show when={(getLikesFlowState() as TweetLikesFlowResult).tweets.length === 0}>
                        <div class="no-likes">It seems you haven't liked any tweet yet.</div>
                    </Show>
                    <For each={(getLikesFlowState() as TweetLikesFlowResult).tweets}>{(tweet, index) =>
                        <TweetView tweet={tweet} index={index} />
                    }</For>
                </div>
            </Show>
            <Show when={getLikesFlowState().kind === 'accountsResult'}>
                <div class="entry-list">
                    <Show when={(getLikesFlowState() as AccountLikesFlowResult).accounts.length === 0}>
                        <div class="no-likes">It seems you haven't liked any account yet.</div>
                    </Show>
                    <For each={(getLikesFlowState() as AccountLikesFlowResult).accounts}>{(account, index) =>
                        <AccountEntryView account={account} index={index}/>
                    }</For>
                </div>
            </Show>
            <Show when={getLikesFlowState().kind === 'error'}>
                <div class="error">
                    <b>Failed to fetch your likes!</b>
                    <div innerHTML={(getLikesFlowState() as FetchError).error.message}></div>
                </div>
            </Show>
        </div>
    </>
}
