import { IFlags } from "./data/client-iflags"

const flags: IFlags = {
    search: {
        urlPrefix: '/api',
        resultCountPerPage: 33,
        maxPageCount: 3
    },
    likes: {}
}

export function getFlags(): IFlags {
    return flags
}
