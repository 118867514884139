import { Accessor, For, Show } from "solid-js"
import { encloseWithParagraph, replaceHyperLinks, prettyDate } from "../utils/text"
import { HeartDiv, ThumbDiv } from "./view-hearts-thumbs"
import { getTweetLikeService, getAccountLikeService } from "../data/client-likes"
import { ClientAccountEntry, FetchingTweetsError, TweetsFetched } from "../data/search"
import { AnimatedDotsView, DotStyle } from "../utils/views"

export interface AccountEntryViewProps {
    account: ClientAccountEntry
    index: Accessor<number>
}

export const AccountEntryView = (props: AccountEntryViewProps) => {
    const tweetsState = props.account.tweets[0]
    return <div class="search-result-entry">
        <div class="account-name">
            <div class="person-link">
                <a href={props.account.accountUrl} target="_blank">
                    {props.index() + 1})&nbsp;
                    {props.account.accountDisplayName !== null && props.account.accountDisplayName.length > 0 ?
                        props.account.accountDisplayName :
                        props.account.accountUrl.replace("https://", "")}
                </a>
            </div>
            <HeartDiv
                like={getAccountLikeService().getLikeSignal(props.account.accountId)}
                account={props.account.accountId}
            />
        </div>
        <Show when={tweetsState().kind === 'fetchingTweets'}>
            <div class="post-content" style="font-style: italic">
                <AnimatedDotsView label="Fetching tweets" dotStyle={DotStyle.RightOnly} count={3} />
            </div>            
        </Show>
        <Show when={tweetsState().kind === 'error'}>
            <div class="post-content">
                <span style="color: red">
                    <b>Error fetching tweets:&nbsp;</b>
                    {(tweetsState() as FetchingTweetsError).error.toString()}
                </span>
            </div>            
        </Show>
        <Show when={tweetsState().kind === 'tweetsFetched'}>
            <For each={(tweetsState() as TweetsFetched).data}>{(tweet) =>
                <>
                    <div class="post-content" innerHTML={encloseWithParagraph(replaceHyperLinks(tweet.content))}>
                    </div>
                    <div class="post-time-row post-border">
                        <div class="post-time">
                            <a href={tweet.tweetUrl} target="_blank">
                                {prettyDate(new Date(tweet.creationTime))}
                            </a>
                        </div>
                        <ThumbDiv
                            like={getTweetLikeService().getLikeSignal(tweet.tweetId)}
                            tweet={tweet.tweetId}
                        />
                    </div>
                </>}
            </For>            
        </Show>
    </div>
}

export const FoundAccountsList = (props: { data: ClientAccountEntry[] } ) => {
    return <div class="entry-list">
        <For each={props.data}>
            {(person, index) => <AccountEntryView account={person} index={index} />}
        </For>
    </div>
}
