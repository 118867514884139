export const gearIcon = new URL('../static/gear.png?width=64', import.meta.url)

export const heartIcon = new URL(`../static/heart.png?width=32&as=webp`, import.meta.url)
export const heartFullIcon = new URL(`../static/heart-full.png?width=32&as=webp`, import.meta.url)
export const heartQuestionIcon = new URL(`../static/heart-question.png?width=32&as=webp`, import.meta.url)
export const thumbIcon = new URL(`../static/like.png?width=32&as=webp`, import.meta.url)
export const thumbFullIcon = new URL(`../static/like-full.png?width=32&as=webp`, import.meta.url)
export const thumbQuestionIcon = new URL(`../static/like-question.png?width=32&as=webp`, import.meta.url)

export const iconsForLikes = [
    heartIcon, heartFullIcon, heartQuestionIcon, thumbFullIcon, thumbIcon, thumbQuestionIcon
]
