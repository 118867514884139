import { createSignal } from "solid-js"

export enum MenuItem {
    Search = 'Search',
    Login = 'Login',
    Likes = 'Likes'
}

class SelectedMenuItemData
{
    item: MenuItem

    constructor(item: MenuItem) {
        this.item = item
    }
}

function makeSelectedMenuItemData() {
    const itemStr = localStorage.getItem('selectedMenuItem') ?? ''
    const item = (() => {
        if (itemStr === MenuItem.Login) {
            return MenuItem.Login
        }
        else if (itemStr == MenuItem.Likes) {
            return MenuItem.Likes
        }
        else {
            return MenuItem.Search
        }
    })()

    return new SelectedMenuItemData(item)
}

const [selectedMenuItem, setSelectedMenuItem] = 
    createSignal<SelectedMenuItemData>(makeSelectedMenuItemData());


export const getSelectedMenuItem = selectedMenuItem

export function changeLastSelectedMenuItem(item: MenuItem) {
    if (item === selectedMenuItem().item) {
        return
    }
    setSelectedMenuItem({item: item})
    localStorage.setItem('selectedMenuItem', item)
}
