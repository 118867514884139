export async function sleep(ms: number): Promise<null> {
    return new Promise((resolve, reject) => {
        setTimeout(resolve, ms)
    })
}

export interface ITimeProvider {
    getDate(): Date
    getTimestamp(): number
}

class BaseTimeProvider implements ITimeProvider {
    getDate() {
        throw new Error('class is abstract')
        return new Date()
    }
    getTimestamp() {
        return this.getDate().getTime()
    }
}

export class CurrentTimeProvider extends BaseTimeProvider {
    override getDate() {
        return new Date()
    }
}

export class FixedTimeProvider extends BaseTimeProvider {
    current: Date

    constructor(current: Date) {
        super()
        this.current = current
    }

    override getDate() {
        return this.current
    }

    setDate(current: Date) {
        this.current = current
    }
}

export function humanReadableTimeDelta(valueMs: number) {
    const ms = valueMs % 1000
    const totalS = (valueMs - ms) / 1000
    const s = totalS % 60
    const totalMin = (totalS - s) / 60
    const min = totalMin % 60
    const totalH = (totalMin - min) / 60
    const h = totalH % 24
    const d = (totalH - h) / 24

    const pieces = []
    if (d > 0) {
        pieces.push(`${d}d`)
    }
    if (h > 0) {
        pieces.push(`${h}h`)
    }
    if (min > 0) {
        pieces.push(`${min}m`)
    }
    if (s > 0) {
        pieces.push(`${s}s`)
    }
    if (pieces.length === 0) {
        pieces.push('0s')
    }
    return pieces.join(' ')
}