import { ClientAccountEntry, getSearchData, makeEmptyAccountSearchData, makeEmptyTweetSearchData, SearchRequestInFlight, setSearchData, TweetsState } from "../data/search"
import { TweetEntry } from '../../common/tweets'
import { AccountEntry } from '../../common/tweets'
import { getSearchSettings, MinTweetLength, SearchSettings, SearchType } from '../data/search'
import { getFlags } from "../flags"
import { fetchJson } from "../../common/json-api"
import { removeDuplicatesFromArray, toArray } from "../../common/data-structures"
import { createSignal } from "solid-js"
import { Enabled, Realm, TweetId } from "../../common/types"
import { sleep } from "../../common/time"
import { getAccountLikeService, getTweetLikeService } from "../data/client-likes"
import { clientLogErr } from "../utils/log"

const LOGSYS = 'Search'

function areSearchSettingsEqual(a: SearchSettings, b: SearchSettings) {
    if (a.query !== b.query) {
        return false
    }
    if (a.searchType !== b.searchType) {
        return false
    }
    if (a.minTweetLengthConstraint !== b.minTweetLengthConstraint) {
        return false
    }
    if (a.minTime.trim() !== b.minTime.trim()) {
        return false
    }
    if (a.maxTime.trim() !== b.maxTime.trim()) {
        return false
    }
    if (a.useBluesky !== b.useBluesky) {
        return false
    }
    if (a.useMastodon !== b.useMastodon) {
        return false
    }
    return true
}

const dateRegexp = new RegExp(/^(\d{4})-(\d{1,2})-(\d{1,2})$/)
const timedeltaRegexp = new RegExp(/^(\d+[ymwd])(\s+\d+[ymwd])*$/)

function timedeltaToMs(v: string) {
    const pieces = v.split(' ')
    let totalMs = 0
    for (const piece of pieces) {
        const trimmed = piece.trim()
        const unit = trimmed[trimmed.length - 1]
        const count = parseInt(trimmed.slice(0, trimmed.length - 1))
        let ms
        if (unit === 'y') {
            ms = 365 * 24 * 3600000
        }
        else if (unit === 'm') {
            ms = 30 * 24 * 3600000
        }
        else if (unit === 'w') {
            ms = 7 * 24 * 3600000
        }
        else {
            ms = 24 * 3600000
        }
        totalMs += count * ms
    }
    return totalMs
}

function absDateMatchToTimestamp(match: RegExpMatchArray) {
    const dateV = `${match[1]}-${match[2].padStart(2, '0')}-${match[3].padStart(2, '0')}T00:00:00.000Z`
    const date = new Date(dateV)
    if (isNaN(date.getTime())) {
        return new Error('date is invalid')
    }
    return date.getTime()
}

function getTimeBounds(a: string, b: string): [number | null, number | null] | null {
    let ra: number | null = null
    const va = a.trim()
    if (va.length !== 0) {
        const dateMatch = dateRegexp.exec(va)
        if (dateMatch !== null) {
            const maybeDate = absDateMatchToTimestamp(dateMatch)
            if (maybeDate instanceof Error) {
                return null
            }
            ra = maybeDate
        }
        else {
            if (timedeltaRegexp.exec(va) !== null) {
                ra = Date.now() - timedeltaToMs(va)
            }
            else {
                return null
            }
        }
    }

    let rb: number | null = null
    const vb = b.trim()
    if (vb.length !== 0) {
        const dateMatch = dateRegexp.exec(vb)
        if (dateMatch !== null) {
            const maybeDate = absDateMatchToTimestamp(dateMatch)
            if (maybeDate instanceof Error) {
                return null
            }
            rb = maybeDate
        }
        else {
            if (timedeltaRegexp.exec(vb) !== null) {
                rb = (ra !== null ? ra : Date.now()) + timedeltaToMs(vb)
            }
        }
    }

    return [ra, rb]
}

function getTweetsAndAccountsFromTweets(data: TweetEntry[]) {
    const accounts = removeDuplicatesFromArray(data.map((tweet) => { return tweet.accountId }))
    const tweets = data.map((tweet) => { return tweet.tweetId })
    return [tweets, accounts]
}

function getTweetsAndAccountsFromAccounts(data: ClientAccountEntry[]) {
    const accounts = data.map((account) => { return account.accountId })
    const tweets: TweetId[] = []
    for (const account of data) {
        const tweetsState = account.tweets[0]()
        if (tweetsState.kind !== 'tweetsFetched') {
            clientLogErr(LOGSYS, `Unexpected tweets state: ${tweetsState.kind}`)
            continue
        }
        for (const tweet of tweetsState.data) {
            tweets.push(tweet.tweetId)
        }
    }
    return [tweets, accounts]
}

let searchRequestCounter = 0

export async function searchFlow(apiKey: string | null) {
    const thisSearchSettings = {...getSearchSettings()}
    const realms: Set<Realm> = new Set()
    if (thisSearchSettings.useMastodon === Enabled.Yes) {
        realms.add(Realm.Mastodon)
    }
    if (thisSearchSettings.useBluesky === Enabled.Yes) {
        realms.add(Realm.Bluesky)
    }

    const currentSearch = getSearchData()
    if (!areSearchSettingsEqual(currentSearch.settings, thisSearchSettings)) {
        // Interrupt currently running search.
        if (currentSearch.request.kind === 'inFlight') {
            currentSearch.request.abort.abort()
        }

        // Forget likes.
        let forgetTweets = null
        let forgetAccounts = null
        if (currentSearch.data.kind === 'tweets') {
            [forgetTweets, forgetAccounts] = getTweetsAndAccountsFromTweets(currentSearch.data.entries)
        }
        else if (currentSearch.data.kind === 'accounts') {
            [forgetTweets, forgetAccounts] = getTweetsAndAccountsFromAccounts(currentSearch.data.entries)
        }
        if (forgetTweets) {
            getTweetLikeService().forgetLikes(forgetTweets)
        }
        if (forgetAccounts) {
            getAccountLikeService().forgetLikes(forgetAccounts)
        }

        // Reset search data.
        if (thisSearchSettings.searchType === SearchType.Accounts) {
            setSearchData(makeEmptyAccountSearchData())
        }
        else {
            setSearchData(makeEmptyTweetSearchData())
        }
    }
    else {
        if (currentSearch.request.kind === 'inFlight' || getSearchData().page >= getFlags().search.maxPageCount) {
            return
        }
    }

    // Verify that settings are correct.
    if (thisSearchSettings.query.length === 0) {
        setSearchData({
            ...getSearchData(),
            request: { kind: 'error', error: new Error('Query is empty.') }}
        )
        return
    }
    if (realms.size === 0) {
        setSearchData({
            ...getSearchData(),
            request: { kind: 'error', error: new Error('Both Bluesky and Mastodon are disabled.') }
        })
        return
    }
    if (thisSearchSettings.augmentSq == Enabled.Yes) {
        thisSearchSettings.query = 'search_query: ' + thisSearchSettings.query
    }
    let bounds = getTimeBounds(thisSearchSettings.minTime, thisSearchSettings.maxTime)
    if (bounds === null) {
        setSearchData({
            ...getSearchData(),
            request: { kind: 'error', error: new Error(`Invalid time range`) }
        })
        return
    }
    let [minTime, maxTime] = bounds

    // Run a new search.
    const thisSearchCounter = ++searchRequestCounter
    const searchRequest: SearchRequestInFlight = {
        kind: 'inFlight',
        abort: new AbortController(),
        counter: thisSearchCounter
    }
    setSearchData({...getSearchData(), request: searchRequest})

    const typeStr = thisSearchSettings.searchType === SearchType.Tweets ? 'tweets' : 'accounts'
    const endpointType = apiKey === null ? 'free' : 'locked'
    const headers = apiKey === null ? {} : {'Authorization': `Bearer ${apiKey}`}
    const requestBody: any = {
        q: thisSearchSettings.query,
        c: (getSearchData().page + 1) * getFlags().search.resultCountPerPage,
        r: toArray(realms).join(',')
    }

    if (thisSearchSettings.searchType === SearchType.Tweets) {
        if (thisSearchSettings.minTweetLengthConstraint === MinTweetLength.Mid) {
            requestBody['l'] = 128
        }
        else if (thisSearchSettings.minTweetLengthConstraint === MinTweetLength.Long) {
            requestBody['l'] = 512
        }
        else if (thisSearchSettings.minTweetLengthConstraint === MinTweetLength.Huge) {
            requestBody['l'] = 2048
        }
    
        if (minTime !== null) {
            requestBody['tmin'] = minTime
        }
        if (maxTime !== null) {
            requestBody['tmax'] = maxTime
        }
    }

    let response
    const simulatedErrorTimeout = getFlags().search.simulateFetchErrorTimeout
    if (simulatedErrorTimeout) {
        console.log('simulating error')
        await sleep(simulatedErrorTimeout)
        response = new Error('Simulated network error.')
    }
    else {
        response = await fetchJson(
            new Request(
                `${getFlags().search.urlPrefix}/${endpointType}/search/${typeStr}`,
                {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(requestBody)
                }
            ),
            searchRequest.abort.signal
        )
    }
    if (searchRequestCounter !== thisSearchCounter) {
        return
    }

    if (response instanceof Error) {
        setSearchData({...getSearchData(), request: { kind: 'error', error: response }})
        return
    }

    const responseEntries = response as unknown[]
    const currentEntries = getSearchData().data
    const currentIdSet = new Set()
    if (currentEntries.kind === 'accounts') {
        for (const item of currentEntries.entries) {
            currentIdSet.add(item.accountId)
        }
        for (const item of responseEntries as AccountEntry[]) {
            if (currentIdSet.has(item.accountId))
                continue
            currentEntries.entries.push({...item, tweets: createSignal<TweetsState>({kind: 'tweetsFetched', data: item.tweets})})
        }
    }
    else {
        for (const item of currentEntries.entries) {
            currentIdSet.add(item.tweetId)
        }
        for (const item of responseEntries as TweetEntry[]) {
            if (currentIdSet.has(item.tweetId))
                continue
            currentEntries.entries.push(item)
        }
    }
    setSearchData({...getSearchData(), request: { kind: 'idle' }, page: getSearchData().page + 1})

    // Request likes.
    let tweets = null
    let accounts = null
    if (currentEntries.kind === 'tweets') {
        [tweets, accounts] = getTweetsAndAccountsFromTweets(currentEntries.entries)
    }
    else if (currentEntries.kind === 'accounts') {
        [tweets, accounts] = getTweetsAndAccountsFromAccounts(currentEntries.entries)
    }
    if (tweets) {
        getTweetLikeService().requestLikesByIds(tweets)
    }
    if (accounts) {
        getAccountLikeService().requestLikesByIds(accounts)
    }
}
