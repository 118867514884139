import { AccountData, TweetData, TweetEntry } from "../../common/tweets"
import { AccountId } from "../../common/types"
import { getFlags } from "../flags"
import { fetchJson } from "../../common/json-api"
import { getApiKeyData } from "./client-auth"
import { LatestTweetCount } from "./client-likes"

export class LatestTweetService {
    _tweets: Map<LatestTweetCount, Map<AccountId, TweetData[]>>

    constructor() {
        this._tweets = new Map()
        this._tweets.set(LatestTweetCount.Min, new Map())
        this._tweets.set(LatestTweetCount.Mid, new Map())
        this._tweets.set(LatestTweetCount.Max, new Map())
    }

    async getLatestTweetsOfAccount(id: AccountId, count: LatestTweetCount): Promise<TweetData[] | Error> {
        const latest = this._tweets.get(count)?.get(id)
        if (latest) {
            return latest
        }

        const countNum = ((count) => {
            if (count === LatestTweetCount.Max) {
                return 10
            }
            else if (count === LatestTweetCount.Mid) {
                return 5
            }
            else {
                return 3
            }
        })(count)

        const response = await fetchJson(
            new Request(
                `${getFlags().search.urlPrefix}/locked/tweets/latest-of-account?a=${id}&c=${countNum}`,
                { method: 'GET', headers: {'Authorization': `Bearer ${getApiKeyData().validKey}`} }
            )
        )
        if (response instanceof Error) {
            return response
        }
        
        const tweets = response as unknown as TweetData[]
        this._tweets.get(count)?.set(id, tweets)
        return tweets
    }
}

const latestTweetService = new LatestTweetService()

export function getLatestTweetService() { return latestTweetService }

type Response<T> = {[Key: string]: T | null}

export class ClientObjectService<T> {
    _objects: Map<string, T>
    _api: string

    constructor(api: string) {
        this._objects = new Map()
        this._api = api
    }

    async getAccountsByIds(ids: string[]): Promise<Map<string, T> | Error> {
        const apiKey = getApiKeyData().validKey
        if (!apiKey) {
            return new Error('API key is not set')
        }
        const remoteIds = []
        for (const id of ids) {
            if (!this._objects.has(id)) {
                remoteIds.push(id)
            }
        }
        if (remoteIds.length > 0) {
            const response = await fetchJson(
                new Request(
                    `${getFlags().search.urlPrefix}/locked/${this._api}/by-ids`,
                    {
                        method: 'POST',
                        headers: {'Authorization': `Bearer ${apiKey}`},
                        body: JSON.stringify({ids: remoteIds})
                    }
                )
            )
            if (response instanceof Error) {
                return response
            }
            const objects = response as unknown as Response<T>
            for (const id in objects) {
                if (objects[id] !== null) {
                    this._objects.set(id, objects[id])
                }
                    
            }
        }
        const result = new Map()
        for (const id of ids) {
            if (this._objects.has(id)) {
                result.set(id, this._objects.get(id))
            }
        }
        return result
    }
}

const accountService: ClientObjectService<AccountData> = new ClientObjectService('accounts')
const tweetService: ClientObjectService<TweetEntry> = new ClientObjectService('tweets')

export function getClientAccountService() { return accountService }
export function getClientTweetService() { return tweetService }
