import { KeyVerificationInFlight, changeCurrentKey, getKeyVerificationFlowState, getApiKeyData } from "../data/client-auth";
import { Show } from "solid-js/web";
import { apiKeyVerificationFlow } from "../logic/auth-flow";
import { AnimatedDotsView, DotStyle } from "../utils/views";

export const VerifyKeyView = () => {
    return <div>
        <div class="auth-bar">
            <Show when={!getApiKeyData().validKey}>
                <input
                    value={getApiKeyData().currentKey}
                    onInput={(ev) => { changeCurrentKey(ev.target.value); }}
                    placeholder="Enter API key..."
                />
                <div class="complex-button">
                    <div
                        class="highlight enlarged-padding"
                        onClick={async (ev) => { await apiKeyVerificationFlow( getApiKeyData().currentKey ) }}
                    >
                        Log in
                    </div>
                </div>
            </Show>
            <Show when={getKeyVerificationFlowState() instanceof KeyVerificationInFlight}>
                <AnimatedDotsView dotStyle={DotStyle.RightOnly} label="Logging in" />
            </Show>
        </div>
        <Show when={getKeyVerificationFlowState() instanceof Error}>
            <div class="auth-error">
                <b>Failed to log in:&nbsp;</b>
                <span innerHTML={(getKeyVerificationFlowState() as Error).message} />
            </div>
        </Show>
    </div>
}
