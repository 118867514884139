import { apiKeyValidated, KeyVerificationInFlight, changeKeyVerificationFlowState, dropValidKey, getKeyVerificationFlowState } from "../data/client-auth"
import { getFlags } from "../flags"
import { fetchJson } from "../../common/json-api"
import { MenuItem, changeLastSelectedMenuItem } from "../data/menu"
import { findInvalidSymbolInToken } from "../../common/auth"

export function logOut() {
    dropValidKey()
    changeLastSelectedMenuItem(MenuItem.Search)
}

export async function apiKeyVerificationFlow(apiKey: string) {
    if (getKeyVerificationFlowState() instanceof KeyVerificationInFlight) {
        return
    }

    if (apiKey.length === 0) {
        return
    }

    const invalidSymbol = findInvalidSymbolInToken(apiKey)

    if (invalidSymbol !== null) {
        const errorMsg = [
            `Your API key is invalid, because it contains invalid symbol: `,
            `<span style="color: black">${invalidSymbol}</span>`
        ].join("")
        changeKeyVerificationFlowState(new Error(errorMsg))
        return
    }

    changeKeyVerificationFlowState(new KeyVerificationInFlight())

    const response = await fetchJson(
        new Request(`${getFlags().search.urlPrefix}/free/check-key?key=${apiKey}`, {method: 'GET'})
    )
    if (response instanceof Error) {
        changeKeyVerificationFlowState(response)
        return
    }

    if (!response) {
        changeKeyVerificationFlowState(new Error('API key is invalid'))
        return
    }

    if (response) {
        changeKeyVerificationFlowState(null)
        apiKeyValidated(apiKey)
        changeLastSelectedMenuItem(MenuItem.Search)
    }
}
